<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col cols="12" sm="8" md="8">
                <v-data-table
                    :search="search"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="tags"
                    :items-per-page="30"
                    class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-col cols="10">
                                <v-text-field
                                    v-model="search"
                                    label="Search"
                                    class="mx-4"
                                ></v-text-field>
                            </v-col>
        
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        New Tag
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                    </v-card-title>
        
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="editedItem.title"
                                                    label="Tag name"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
        
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="editedItem.group"
                                                    label="Tag group"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
        
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="editedItem.is_male"
                                                    label="Male"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
        
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="editedItem.is_female"
                                                    label="Female"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
        
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="editedItem.is_transgender"
                                                    label="Transgender"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
        
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn color="blue darken-1" text @click="save">
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title
                                        class="text-h5 align-center justify-center text-center full-width"
                                        >Are you sure you want <br />to delete this
                                        tag?</v-card-title
                                    >
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeDelete"
                                            >Cancel</v-btn
                                        >
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="deleteItemConfirm"
                                            >OK</v-btn
                                        >
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize"> Reset </v-btn>
                    </template>
                    <template v-slot:item.group="{ item }">
                        {{ item.group || "-" }}
                    </template>
                    <template v-slot:item.is_male="{ item }">
                        <IconDone v-if="item.is_male" />
                    </template>
                    <template v-slot:item.is_female="{ item }">
                        <IconDone v-if="item.is_female" />
                    </template>
                    <template v-slot:item.is_transgender="{ item }">
                        <IconDone v-if="item.is_transgender" />
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                        {{
                            item.updated_at ? moment(item.updated_at).format("LLL") : ""
                        }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{
                            item.created_at ? moment(item.created_at).format("LLL") : ""
                        }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import Tag from "@/models/admin/Tag";
import { mapMutations } from "vuex";
import IconDone from "@/assets/images/icons/done.svg?inline";

/* eslint-disable */
export default {
    name: "users-index",
    components: { AdminDashboardLayout, IconDone },
    data: function () {
        return {
            loading: false,
            tags: [],
            search: "",
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
                title: "",
            },
            headers: [
                {
                    text: "# ID",
                    align: "center",
                    value: "id",
                },
                { text: "Tag name", align: "center", value: "title" },
                { text: "Actions", align: "center", value: "actions" },
            ],
            defaultItem: {
                title: "",
            },
        };
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        async initialize() {
            this.loading = true;
            this.tags = await Tag.get();
            this.loading = false;
        },
        handleClickRow(tag) {
            this.$router.push({
                name: "admin.tags.show",
                params: { id: tag.id },
            });
        },
        editItem(item) {
            this.editedIndex = this.tags.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.tags.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            const id = this.tags[this.editedIndex].id;
            const tag = await Tag.find(id);
            this.tags.splice(this.editedIndex, 1);
            tag.delete()
                .then(async () => {
                    this.showSnackBar(this.$t("messages.success"));
                })
                .catch((e) => {
                    console.log(e);
                });
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        async save() {
            let tag = new Tag();
            if (this.editedIndex > -1) {
                Object.assign(this.tags[this.editedIndex], this.editedItem);
                tag = await Tag.find(this.editedItem.id);
            } else {
                this.tags.push(this.editedItem);
            }
            tag = Object.assign(tag, this.editedItem);
            tag.save()
                .then(async () => {
                    this.showSnackBar(this.$t("messages.success"));
                })
                .catch((e) => {
                    console.log(e);
                });
            this.close();
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Tag" : "Edit Tag";
        },
    },
    mounted() {
        this.initialize();
    },
};
</script>

<style scoped></style>
